<style scoped lang="less">
  .evaluation-details {
    margin: 10px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
  }
  .content {
    overflow: auto;
    flex: 1;
    height: 0;
  }
  .tabs {
    padding: 10px;
    span {
      cursor: pointer;
      display: inline-block;
      & + span {
        margin-left: 10px;
      }
      &.active {
        cursor: default;
        color: #000;
      }
      transition: all .3s;
      color: #657180;
      font-size: 15px;
    }
  }
</style>

<template>
  <div class="evaluation-details">
    <div style="display: flex;justify-content: space-between;align-items: center;">
      <div class="tabs">
        <router-link v-if="$authFunProxy['hrms.evaluation.index-my']" :class="{
          active: $route.name === 'hrms.evaluation.details.draw'
        }" tag="span" :to="{
          name: 'hrms.evaluation.details.draw',
          query: { id: $route.query.id }
        }">个人总结填写</router-link>
        <router-link v-if="$authFunProxy['hrms.evaluation.index-mycheck']" :class="{
          active: $route.name === 'hrms.evaluation.details.opinion'
        }" tag="span" :to="{
          name: 'hrms.evaluation.details.opinion',
          query: { id: $route.query.id }
        }">个人意见填写</router-link>
        <router-link v-if="$authFunProxy['hrms.evaluation.index-orgcheck'] || $authFunProxy['hrms.evaluation.index-leadcheck']" :class="{
          active: $route.name === 'hrms.evaluation.details.approve'
        }" tag="span" :to="{
          name: 'hrms.evaluation.details.approve',
          query: { id: $route.query.id }
        }">考核意见填写<Badge :count="total" /></router-link>
      </div>
      <i @click="$router.replace({name: 'hrms.evaluation.index'})" style="cursor: pointer;margin-right: 10px;" class="fmico fmico-cha"></i>
    </div>
    <router-view class="scrollbar content" />
  </div>
</template>

<script>
import { appraisalYearDetailsRequest } from '../../api'

export default {
  data () {
    return {
      total: 0
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    }
  },
  watch: {
    id () {
      if (!this.id) {
        return this.$router.replace({ name: 'hrms.evaluation.index' })
      }
    }
  },
  methods: {
    async loadData () {
      let param = {
        appraisalYearId: this.$route.query.id
      }

      const res = await appraisalYearDetailsRequest.get(param)
      if (res.length) {
        this.total = res.reduce((a, b) => {
          return a + (b.isUpcoming ? 1 : 0)
        }, 0)
      } else {
        this.$notice.error('数据不存在')
        this.$router.replace({ name: 'hrms.evaluation.index' })
      }
    }
  },
  mounted () {
    if (this.$authFunProxy['hrms.evaluation.index-getDetails']) {
      this.loadData()
    } else {
      this.$notice.error('无明细查看权限')
      this.$router.back()
      return
    }
    const routerMap = {
      my: 'draw',
      mycheck: 'opinion',
      orgcheck: 'approve',
      leadcheck: 'approve'
    }
    for (const auth of ['my', 'mycheck', 'orgcheck', 'leadcheck']) {
      if (this.$authFunProxy['hrms.evaluation.index-' + auth]) {
        const routerName = 'hrms.evaluation.details.' + routerMap[auth]
        if (this.$route.name === 'hrms.evaluation.details') {
          this.$router.replace({
            name: routerName,
            query: {
              id: this.$route.query.id
            }
          })
        }
        break
      }
    }
  }
}
</script>